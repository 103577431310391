<template>
  <!-- index.vue -->

  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
    }"
    :class="{
      'sidebar-close': !getThemeMode.verticalSidebarDrawer,
    }"
  >
    <app-bar />

    <base-view />
    <renew-token />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    BaseView: () => import("./View"),
    AppBar: () => import("./AppBar"),
    RenewToken: () => import("@/components/global/RenewToken"),
  },

  computed: {
    ...mapGetters(["getThemeMode"]),

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style lang="scss">
.v-skeleton-loader__image {
  height: 100% !important;
}
.v-skeleton-loader__heading {
  max-height: 100% !important;
  width: 100% !important;
}
.mb-0 {
  .v-skeleton-loader__text {
    margin-bottom: 0px !important;
  }
}
</style>
